"use client";

import { Button, Typography, useTheme } from "@mui/material";
import { IconAlertCircle } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { useCallback, useEffect } from "react";

import { logError } from "~/client/logger";

export default function ErrorPage({
  error,
  reset,
}: {
  error?: Error & { digest?: string };
  reset?: () => void;
}) {
  useEffect(() => {
    error && logError(error);
  }, [error]);

  const theme = useTheme();
  const isAccessDenied =
    error?.name === "ForbiddenError" || error?.message === "Access denied";
  const router = useRouter();

  const onClick = useCallback(() => {
    if (isAccessDenied) {
      router.push("/");
    } else {
      reset ? reset() : window.location.reload();
    }
  }, [isAccessDenied, router, reset]);

  return (
    <>
      <div className="flex min-h-full min-w-full flex-col items-center justify-center">
        <div className="flex w-[460px] flex-col gap-5">
          <div className="flex flex-row justify-center text-center">
            <IconAlertCircle color={theme.palette.primary.main} size={"57px"} />
          </div>
          <Typography color="primary.main" textAlign={"center"} variant="h3">
            {isAccessDenied ? "Access Denied" : "Error"}
          </Typography>
          <Typography color="primary.main" textAlign={"center"} variant="h5">
            {isAccessDenied
              ? `You do not have permission to view this page`
              : `We're sorry, something has gone wrong. Please try again`}
          </Typography>
          {reset && (
            <div className="text-center">
              <Button
                size="large"
                type="button"
                variant="contained"
                onClick={onClick}
              >
                {isAccessDenied ? "Continue" : "Refresh page"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
